.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: 0.25rem;
  border-radius: 0.25rem;
}

.bd-callout h4 {
  margin-top: 0;
  margin-bottom: 0.25rem;
}

.bd-callout p:last-child {
  margin-bottom: 0;
}

.bd-callout code {
  border-radius: 0.25rem;
}

.bd-callout + .bd-callout {
  margin-top: -0.25rem;
}

.bd-callout-info {
  border-left-color: #5bc0de;
}

.bd-callout-info h4 {
  color: #5bc0de;
}

.bd-callout-warning {
  border-left-color: #f0ad4e;
}

.bd-callout-warning h4 {
  color: #f0ad4e;
}

.bd-callout-danger {
  border-left-color: #d9534f;
}

.bd-callout-danger h4 {
  color: #d9534f;
}

.bd-callout-dark {
  border-left-color: #343a40;
}
.bd-callout-dark h4 {
  color: #343a40;
}

.bd-callout-primary {
  border-left-color: #007bff !important;
}
.bd-callout-primary h4 {
  color: #007bff;
}
